


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar{
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html {
  scroll-behavior: smooth;
}

.skillsBox {
  text-align: center;
}

.link :active {
  color: yellow;
}

.technicalStack {
  background-color: #101935;
  height: 35px;
  color: #FFFFFF;
  padding: 5px;
  margin: 0 auto;
  margin-bottom: 80px;
  width: 30%;
  text-align: center;
  justify-content: center;
  border-radius: 20px;
}

img.logo {
text-align: center;
margin: auto;
transition: .2s;
}

img.logo:hover{
  transform: scale(1.05);
  transition: .2s;
}

#bio {
  margin-bottom: 20px;
}
a:active {
  color: yellow;
}

.email {
 background-color: #fae29b;
 height: 40vh;
 display: flex;
 justify-content: center;
 align-items: center;
 margin-bottom: 10px;
}

.submitbuttonemail {
  background-color: 101935;
  color: white;
}

.emailform {

 text-align: center;
 width: 30%;
 background-color: #101935;
 padding-top: 18px;
 border-radius: 40px;
 height: 88%;

}



.emailform input, textarea {
margin: 2px;
padding: 8px; 
width: 70%;
border-radius: 10px;
}

.emailform textarea {
  height: 15vh;
}

.contactme {
  background-color: #101935;
  height: 35px;
  color: #FFFFFF;
  padding: 5px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 30%;
  text-align: center;
  justify-content: center;
  border-radius: 20px;
}
@media all and (max-width: 1500px){
 .contactme {
  width: 50%
 }

 .technicalStack {
  width: 60%;
 }
}

@media all and (max-width: 820px){
  .emailform {
    width: 80%;
    height: 90%;
  }
}