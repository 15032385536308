

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  font-family: 'Comfortaa', cursive;
  background-color: #fae29b;
}

